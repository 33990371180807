import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFetchHome } from "hooks/api/useFetchHome";
import { useFetchUnscheduledEvents } from "hooks/api/useFetchUnscheduledEvents";

import { LoadingContext, actionTypes } from "context/LoadingContext";

import { Box } from "components/Base/Box/Box";
import { BoxHeader } from "components/Base/Box/BoxHeader/BoxHeader";
import { BoxSection } from "components/Base/Box/BoxSection/BoxSection";
import { StaggeredAnimation } from "components/Base/Box/StaggeredAnimation/StaggeredAnimation";
import { FetchError } from "components/Base/FetchError/FetchError";
import { List } from "components/Base/List/List";
import { UnscheduledEventListItem } from "../UnscheduledEventListItem/UnscheduledEventListItem";

import "../EventList/EventList.css";


export function UnscheduledEventList() {
  const { t } = useTranslation();
  const { dispatch } = useContext(LoadingContext);

  const { isLoading: isLoadingStatus, status } = useFetchHome();
  const { events = [], error, isLoading: isLoadingEvents } = useFetchUnscheduledEvents();

  const isLoading = isLoadingEvents || isLoadingStatus;

  useEffect(() => {
    const LOADING_KEY = "unscheduled-events";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <BoxHeader>
        {error ? (
          <FetchError />
        ) : (
          <h2 className="event-list__title">
            {t("events.unscheduled_events")}
          </h2>
        )}
      </BoxHeader>
      <StaggeredAnimation animationKey="unscheduled-events">
        <BoxSection>
          {events.length === 0 || status.studyLocked ? (
            <p className="event-list__info">
              {t("events.no_unscheduled_events")}
            </p>
          ) : (
            <List
              items={events}
              render={(event) => <UnscheduledEventListItem event={event} />}
            />
          )}
        </BoxSection>
      </StaggeredAnimation>
    </Box>
  );
}
