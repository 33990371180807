import React, {
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import SignaturePad from "signature_pad";
import body from "content/images/body.png";
import sign from "content/images/sign.png";

import "./DrawingPad.css";

const backgroundImages = {
  sign,
  body,
};

const defaultBackgroundRatio = 0.5;
const ratio = Math.max(window.devicePixelRatio || 1, 1);

const getBackgroundRatio = (backgroundImage) => {
  return backgroundImage
    ? backgroundImage.height / backgroundImage.width
    : defaultBackgroundRatio;
};

const redrawBackgroundImage = (canvasEl, image) => {
  canvasEl
  .getContext("2d")
  .drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    0,
    0,
    canvasEl.width / ratio,
    canvasEl.height / ratio
  );
}

export const DrawingPad = forwardRef(
  ({ backgroundImageName, onChange, value }, ref) => {
    const canvas = useRef(null);
    const signaturePad = useRef(null);
    const backgroundImage = useRef(null);
    const currentValue = useRef(value);

    // Enable clearing the signature pad from the parent component
    useImperativeHandle(ref, () => ({
      clear() {
        updateValue(null, true);
        redrawImage();
      },
    }));

    /**
     * Updates the current value and calls the onChange callback.
     */
    const updateValue = useCallback(
      (newValue, isReset) => {
        currentValue.current = newValue;
        onChange(newValue, undefined, isReset);
      },
      [onChange]
    );

    /**
     * Clears the signature pad and redraws background image and current value.
     */
    const redrawImage = useCallback(() => {
      const image = backgroundImage.current;

      if (!signaturePad.current || !canvas.current) {
        return;
      }

      signaturePad.current.clear();

      if (image) {
        redrawBackgroundImage(canvas.current, image);
      }

      if (currentValue.current) {
        signaturePad.current.fromDataURL(currentValue.current);
      }
    }, []);

    /**
     * Resize the canvas element.
     */
    const resizeCanvas = useCallback(() => {
      const canvasEl = canvas.current;
      const backgroundRatio = getBackgroundRatio(backgroundImage.current);

      canvasEl.width = canvasEl.offsetWidth * ratio;
      canvasEl.height = canvasEl.offsetWidth * ratio * backgroundRatio;
      canvasEl.getContext("2d").scale(ratio, ratio);

      redrawImage();
    }, [redrawImage]);

    /**
     * Update value and add resize event listener when stroke ends.
     */
    const handleEndStroke = useCallback(() => {
      updateValue(signaturePad.current.toDataURL());
      window.addEventListener("resize", resizeCanvas);
    }, [updateValue, resizeCanvas]);

    /**
     * Remove resize event listener when stroke begins.
     */
    const handleBeginStroke = useCallback(() => {
      window.removeEventListener("resize", resizeCanvas);
    }, [resizeCanvas]);

    // Initialize signature pad
    useEffect(() => {
      const options = {
        backgroundColor: "#FCFCFC",
        minWidth: 1.5, // Minimum width of a line
        maxWidth: 3, // Maximum width of a line
      };

      if (canvas.current) {
        signaturePad.current = new SignaturePad(canvas.current, options);
      }

      return () => {
        if (signaturePad.current) {
          signaturePad.current.off();
        }
      };
    }, []);

    // Add signature pad event handlers
    useEffect(() => {
      if (signaturePad.current) {
        signaturePad.current.addEventListener("endStroke", handleEndStroke);
        signaturePad.current.addEventListener("beginStroke", handleBeginStroke);
      }

      return () => {
        if (signaturePad.current) {
          signaturePad.current.removeEventListener("endStroke", handleEndStroke);
          signaturePad.current.removeEventListener("beginStroke", handleBeginStroke);          
        }
      }
    }, [handleBeginStroke, handleEndStroke]);

    // Load background image
    useEffect(() => {
      if (backgroundImageName) {
        backgroundImage.current = new Image();
        backgroundImage.current.src = backgroundImages[backgroundImageName];
        backgroundImage.current.onload = resizeCanvas;
      } else {
        resizeCanvas();
      }

      return () => {
        if (backgroundImage.current) {
          backgroundImage.current.onload = undefined;
        }
      };
    }, [backgroundImageName, resizeCanvas]);

    // Add resize event listener
    useEffect(() => {
      window.addEventListener("resize", resizeCanvas);

      return () => {
        window.removeEventListener("resize", resizeCanvas);
      };
    }, [resizeCanvas]);

    return <canvas ref={canvas} className="drawing-pad"></canvas>;
  }
);

DrawingPad.propTypes = {
  backgroundImageName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
