import { React, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ReactComponent as CloseIcon } from "content/icons/close.svg";

import "./MainMenuDrawer.css";
import { Footer, FooterTheme } from "./Footer/Footer";
export function MainMenuDrawer({ onClose, onLogout, onShowHelp, isOpen = false }) {
  const transitionDuration = 500;
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState(true);

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(() => {
        setVisible(true);
      }, transitionDuration);
      return () => clearTimeout(timer);
    } else {
      setVisible(false);
    }
  }, [isOpen]);

  const showHelp = () => {
    onClose();
    onShowHelp();
  };

  const links = [
    { path: "/study", label: t("study_info.study_info") },
    { path: "/settings", label: t("settings.settings") },
  ];

  return (
    <div
      className={classNames({
        "main-menu-drawer": true,
        "main-menu-drawer--open": isOpen,
        "main-menu-drawer--hidden": isVisible,
      })}
      data-testid="mainMenuDrawer"
    >
      <div
        className="main-menu-drawer__overlay"
        onClick={onClose}
        role="presentation"
      ></div>
      <nav className="main-menu-drawer__content" tabIndex="-1">
        <button
          className="main-menu-drawer__close-button"
          onClick={onClose}
          aria-label={t("general.close")}
        >
          <CloseIcon className="main-menu-drawer__close-icon" />
        </button>
        <div className="main-menu-drawer__scroll-content">
          <ul className="main-menu-drawer__link-list">
            {links.map((link, i) => (
              <li
                className={classNames({
                  "main-menu-drawer__link-list-item": true,
                  "main-menu-drawer__link-list-item--mobile-only":
                    link.mobileOnly,
                })}
                key={i}
              >
                <RouterLink
                  className="main-menu-drawer__link"
                  to={link.path}
                  onClick={onClose}
                >
                  {link.label}
                </RouterLink>
              </li>
            ))}
            <li
              className={classNames({
                "main-menu-drawer__link-list-item": true,
                "main-menu-drawer__link-list-item--mobile-only": true,
              })}
            >
              <button
                className="main-menu-drawer__button-link"
                onClick={showHelp}
              >
                {t("general.help.get_help")}
              </button>
            </li>
            <li className="main-menu-drawer__link-list-item">
              <button
                className="main-menu-drawer__button-link"
                onClick={onLogout}
              >
                {t("login.logout")}
              </button>
            </li>
          </ul>
        </div>
        <Footer alignText="left" theme={FooterTheme.DARK} />
      </nav>
    </div>
  );
}

MainMenuDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onShowHelp: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};
