import userManager, { signoutRedirect, signinRedirect } from "utils/oidcClientExtension";
import { IDENTITY_CONFIG } from "utils/identityConfig";
import { User } from "oidc-client";
import { getCookie, eraseCookie } from "helpers/cookies";

const isLoggedIn = () => {
    const token = getLocalToken();
    return token != null;
};

const logout = async () => {
    eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    await signoutRedirect();
};

const getUser = async () => {
    return await userManager.getUser();
};

const onLoginRedirect = (loginHint, auth_method) => signinRedirect(loginHint, auth_method);

function getLocalStorageValue() {
    const localStorageValue = localStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`);
    return localStorageValue ? User.fromStorageString(localStorageValue) : null;
}

function getLocalToken() {
    // since we turned on automatic silent renew token so this local storage value will update for each time renew
    // automatic renew will be execute base on accessTokenExpiringNotificationTime configuration
    // https://github.com/IdentityModel/oidc-client-js/wiki
    const value = getLocalStorageValue();
    if (value) {
        return value.access_token;
    }
    // we already change the way to store access token from cookie to local storage
    // but worker generate pdf still use this cookie to generate so it's kind of fallback token for worker
    return getCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
}

export const authenticationService = {
    logout,
    isLoggedIn,
    onLoginRedirect,
    getLocalToken,
    getUser
};
