import PropTypes from "prop-types";
import classNames from "classnames";
import "./BoxHeader.css";

export function BoxHeader({
  children,
  className,
  element: HtmlTag = "header",
  forwardedRef,
  sticky = true,
}) {
  return (
    <HtmlTag
      className={classNames({
        [className]: className && className !== "",
        box__header: true,
        "box__header--sticky": sticky,
      })}
      ref={forwardedRef}
    >
      {children}
    </HtmlTag>
  );
}

BoxHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  element: PropTypes.string,
  forwardedRef: PropTypes.object,
  sticky: PropTypes.bool,
};
