import "components/viedocui/index";
import "./App.css";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
    LoadingContext,
    LoadingContextProvider,
    actionTypes,
} from "context/LoadingContext";
import { UserContextProvider } from "context/UserContext";
import { VideoContextProvider } from "context/VideoContext";
import "./localization/i18n";

import { useLoadingOverlayDelay } from "hooks/useLoadingOverlayDelay";

import { ErrorBoundary } from "components/Base/ErrorBoundary/ErrorBoundary";
import { LoadingOverlay } from "components/Base/LoadingOverlay/LoadingOverlay";
import { ChangePin } from "components/ChangePin/ChangePin";
import { Home } from "components/Home/Home";
import { Pdf } from "components/Pdf/Pdf";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";
import { Settings } from "components/Settings/Settings";
import { StudyInfo } from "components/StudyInfo/StudyInfo";
import { RequireAuth } from "./components/Base/RequireAuth/RequireAuth";
import { MainLayout } from "./components/Layout/MainLayout";
import { PublicLayout } from "./components/Layout/PublicLayout";
import { NotFound } from "./components/NotFound/NotFound";
import { EventList } from "components/Events/EventList/EventList";
import { UnscheduledEventList } from "components/Events/UnscheduledEventList/UnscheduledEventList";
import { ValidatePageIdForm } from "components/ValidatePageIdForm/ValidatePageIdForm";

import { Login } from "components/Login/Login";
import SigninOidc from 'components/Login/signin-oidc';

import { Logout } from "components/Logout/logout";
import SignoutOidc from 'components/Logout/signout-oidc';

import { authenticationService } from "services/authentication";
import userManager from "utils/oidcClientExtension";

import { Welcome } from "components/Welcome/Welcome";

import { UserContext } from "context/UserContext";

import {
    ShowLoadingContext,
    ShowLoadingContextProvider,
} from "context/ShowLoadingContext";

import { IDENTITY_CONFIG } from "utils/identityConfig";

const KEEP_USER_ACTIVE_INTERVAL = 10 * 1000; // 10s

function Main() {
    const navigate = useNavigate();
    const { i18n, ready: translationsReady } = useTranslation(undefined, {
        useSuspense: false,
    });

    const {busyFormItems} = useContext(UserContext);

    const { isLoading, dispatch } = useContext(LoadingContext);
    const { showLoading, setShowLoading } = useContext(ShowLoadingContext);
    const showLoadingOverlay = useLoadingOverlayDelay(isLoading);

    const { pathname } = useLocation();
    const isPdfMode = pathname.startsWith("/pdf/");
    const isAuthenticated = authenticationService.isLoggedIn();

    useEffect(() => {
        userManager.events.addUserSignedOut(async () => {
            navigate('/signout-oidc');
        });
        
        // Returns promise to notify the parent window of response from the authorization endpoint.
        userManager.signinSilentCallback();

    // Navigate is not included in dependency array to avoid hook on every navigation
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setShowLoading(showLoadingOverlay);
    }, [setShowLoading, showLoadingOverlay]);

    useEffect(() => {
        const LOADING_KEY = "translations";
        const type = translationsReady
            ? actionTypes.REMOVE_LOADING_ITEM
            : actionTypes.ADD_LOADING_ITEM;

        dispatch({
            type,
            key: LOADING_KEY,
        });
    }, [dispatch, translationsReady]);

    document.body.dir = i18n.dir();
    document.documentElement.lang = i18n.language;

    const [isKeepUserActive, setKeepUserActive] = useState(false);

    useEffect(() => {
        setKeepUserActive(busyFormItems.length > 0);
    }, [busyFormItems]);

    const inactivityTracker = document.getElementsByTagName('viedoc-inactivity-tracker-cross-domain')[0];
    useEffect(() => {
        if(inactivityTracker){
            inactivityTracker.tokenCallback = () => { return authenticationService.getLocalToken(); };
            inactivityTracker.logoutCallback = () => { return authenticationService.logout(); };
            return () => {
              delete inactivityTracker.tokenCallback;
              delete inactivityTracker.logoutCallback;
            };
        }
      }, [inactivityTracker]); 

    const heartBeatUrl = `${IDENTITY_CONFIG.authority}/api/heartbeat` 

    return (
      <ErrorBoundary>
        {isAuthenticated && !isPdfMode && (
          <viedoc-inactivity-tracker-cross-domain
            heartbeat-url={heartBeatUrl}
            is-single-page
            hot-reload
            keep-alive={isKeepUserActive || null}
            keep-user-active-interval={
              isKeepUserActive ? KEEP_USER_ACTIVE_INTERVAL : null
            }
          ></viedoc-inactivity-tracker-cross-domain>
        )}
        <LoadingOverlay show={showLoading} />
        {translationsReady && (
          <Routes>
            <Route path="/signout-oidc" element={<SignoutOidc />} />
            <Route path="/signin-oidc" element={<SigninOidc />} />
            <Route path="/logout" element={<Logout />} />
            <Route element={<RequireAuth />}>
              <Route path="/pdf/:formId" element={<Pdf />} />
            </Route>
            <Route element={<PublicLayout />}>
              <Route path="/login" element={<Login />} />
              <Route element={<RequireAuth />}>
                <Route path="/change-pin" element={<ChangePin />} />
                <Route path="/welcome" element={<Welcome />} />
              </Route>
            </Route>
            <Route path="/" element={<MainLayout />}>
              <Route element={<RequireAuth />}>
                <Route index element={<Home />} />
                <Route path="study" element={<StudyInfo />} />
                <Route path="settings" element={<Settings />} />
                <Route path="events">
                  <Route index element={<EventList />} />
                  <Route
                    path=":eventId/form/:page"
                    element={<ValidatePageIdForm />}
                  />
                </Route>
                <Route path="unscheduled-events">
                  <Route index element={<UnscheduledEventList />} />
                  <Route
                    path=":eventId/form/:page"
                    element={<ValidatePageIdForm />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        )}
      </ErrorBoundary>
    );
}

export function App() {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <LoadingContextProvider>
                    <ShowLoadingContextProvider>
                        <VideoContextProvider>
                            <UserContextProvider>
                                <ScrollToTop />
                                <Main />
                            </UserContextProvider>
                        </VideoContextProvider>
                    </ShowLoadingContextProvider>
                </LoadingContextProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );
}