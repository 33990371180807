import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "content/icons/arrow.svg";

import "./ArrowBadge.css";

export function ArrowBadge({ className, color = "grey" }) {
  return (
    <div
      className={classNames({
        "arrow-badge": true,
        [`arrow-badge--${color}`]: !!color,
        [className]: !!className,
      })}
    >
      <ArrowIcon className="arrow-badge__icon" />
    </div>
  );
}

ArrowBadge.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["green", "white", "grey"]),
};
