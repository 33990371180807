import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import { VideoContext } from "context/VideoContext";
import { useFetchRoom } from "hooks/api/useFetchRoom";

import { ErrorBoundary } from "components/Base/ErrorBoundary/ErrorBoundary";
import { VideoOverlay } from "components/VideoOverlay/VideoOverlay";
import { MainHeader } from "../MainHeader/MainHeader";
import { Footer } from "./Footer/Footer";

import "./MainLayout.css";

export function MainLayout() {
  const { room } = useContext(VideoContext);
  const { room: activeRoom } = useFetchRoom();

  return (
    <>
      <VideoOverlay room={room} activeRoom={activeRoom} />
      <ErrorBoundary>
        <div className="main-layout">
          <div className="main-layout__content">
            <MainHeader />
            <main className="main-layout__inner-content">
              <Outlet />
            </main>
            <Footer className="main-layout__footer" />
          </div>
        </div>
      </ErrorBoundary>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};