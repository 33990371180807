import "./FileUpload.css";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fileDownload } from "helpers/file-download";
import { Button } from "components/Base/Button/Button";
import { errorDescribedBy } from "components/Base/ValidationError/errorDescribedBy";
import {
  DisplayThumbnail,
  FileDetails,
} from "components/Base/FileDetails/FileDetails";
import { FilePreview } from "components/Base/FilePreview/FilePreview";
import { FileSelect } from "components/Base/FileSelect/FileSelect";
import { FileUploadProgress } from "components/Base/FileUploadProgress/FileUploadProgress";
import { formService } from "services/form";
import { http } from "services/http";
import { ReactComponent as TrashIcon } from "content/icons/trash.svg";
import { Thumbnail } from "components/Base/Thumbnail/Thumbnail";
import { ValidationError } from "components/Base/ValidationError/ValidationError";

export function FileUpload({
  ariaDescribedBy,
  layout,
  onBusy,
  oid,
  onChange,
  readOnly,
  value,
}) {
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const shouldAbort = useRef(false);

  const CHUNK_SIZE = 2097152; // 2 * (1024 * 1024) bytes = 2 MB

  const generateThumb = layout.displayThumbnail !== DisplayThumbnail.NONE;
  const submittedFile =
    value && value.FileName
      ? {
          fileGuid: value.FileGuid,
          fileName: value.FileName,
          fileSize: value.FileSize,
          thumbnail: value.Thumbnail,
        }
      : null;

  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState();
  const [studyFile, setStudyFile] = useState(null);

  const getUploadedFileInfo = useCallback(
    async (fileGuid) => {
      // Early return if uploaded file is already loaded
      if (studyFile && studyFile.fileGuid === fileGuid) {
        return;
      }

      const uploadedFileInfo = await formService.getFileInfo(fileGuid);
      setStudyFile(uploadedFileInfo);
    },
    [studyFile]
  );

  // Keep track of whether file upload is mounted or not,
  // this since progress could be updated after user navigated away during active upload
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // If we have already uploaded a file, i.e. after upload but before submit
    if (value && value.fileGuid) {
      getUploadedFileInfo(value.fileGuid);
    }
  }, [value, getUploadedFileInfo]);

  const updateFileInfo = (newFileInfo) => {
    setStudyFile(newFileInfo);
    onChange(
      newFileInfo
        ? {
            studyFileId: newFileInfo.studyFileId,
            studyId: newFileInfo.studyId,
            studySiteId: newFileInfo.studySiteId,
            fileGuid: newFileInfo.fileGuid,
          }
        : null
    );
  };

  const updateIsUploading = (nextIsUploading) => {
    setIsUploading(nextIsUploading);
    onBusy(nextIsUploading);
  };

  const cancelFileUpload = () => {
    shouldAbort.current = true;
  };

  const uploadFile = async (e) => {
    setError(null);
    const fileList = e.target.files;
    if (fileList.length < 1) {
      return;
    }

    updateIsUploading(true);

    const file = fileList[0];
    const fileSize = file.size;
    let fileStreamPos = 0;
    let endPos = CHUNK_SIZE;
    const fileChunks = [];

    // Add to the file chunk array until we get to the end of the file
    while (fileStreamPos < fileSize) {
      // "slice" the file from the starting position/offset, to the required length
      fileChunks.push(file.slice(fileStreamPos, endPos));
      fileStreamPos = endPos; // jump by the amount read
      endPos = fileStreamPos + CHUNK_SIZE; // set next chunk length
    }

    const fileName = file.name;
    var totalParts = fileChunks.length;

    if (totalParts < 1) {
      setError({ message: t("form.file_upload.file_empty") });
      updateIsUploading(false);
      e.target.value = null;
      return;
    }

    // Upload all chunks
    try {
      let uuid = "";
      for (
        let partIndex = 0;
        !shouldAbort.current && partIndex < totalParts;
        partIndex++
      ) {
        const formData = new FormData();
        formData.append("generateThumb", generateThumb);
        formData.append("partIndex", partIndex);
        formData.append("totalParts", totalParts);
        formData.append("totalSize", fileSize);
        formData.append("uuid", uuid);
        formData.append("fileName", fileName);
        formData.append("file", fileChunks[partIndex]);
        uuid = await formService.uploadFile(
          formData,
          (event) => {
            const partProgress = Math.round((partIndex / totalParts) * 100);
            const totalProgress =
              Math.round((event.loaded / event.total / totalParts) * 100) +
              partProgress;
            isMounted.current && setProgress(totalProgress);
          },
          () => {
            return shouldAbort.current;
          }
        );
      }

      // Aborted XMLHttpRequest will throw error
      // However, chunk might not be aborted if using fast internet connection due to polling in postWithProgress
      if (shouldAbort.current) {
        setError({ message: t("form.file_upload.canceled") });
        updateIsUploading(false);
      } else {
        // All chunks are uploaded. If more than one chunk, ensure to merge them to one file
        if (totalParts > 1) {
          uuid = await formService.mergeChunks(uuid, {
            generateThumb: generateThumb,
            totalParts: totalParts,
            totalSize: fileSize,
            fileName: fileName,
          });
        }

        const uploadedFileInfo = await formService.getFileInfo(uuid);

        // NOTE: reset uploading flag before applying new file info to avoid false busy validation
        updateIsUploading(false);
        updateFileInfo(uploadedFileInfo);
      }
    } catch (err) {
      if (isMounted.current) {
        const message =
          err.status && err.status === http.STATUS_CODES.ABORTED
            ? t("form.file_upload.canceled")
            : err.message ?? t("form.file_upload.file_error");
        setError({ message: message });
        updateIsUploading(false);
      }
    } finally {
      setProgress(0);
      shouldAbort.current = false;
    }

    e.target.value = null;
  };

  const removeFile = () => {
    updateFileInfo(null);
  };

  const fileInfo = submittedFile ?? studyFile;

  const downloadFile = async () => {
    try {
      setIsDownloading(true);
      const file = await formService.downloadFile(fileInfo.fileGuid);
      fileDownload(file, fileInfo.fileName, file.type);
    } catch (err) {
      setError({
        message: err.message ?? t("form.file_upload.download_error"),
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="file-upload">
      <FileSelect
        ariaDescribedBy={ariaDescribedBy}
        label={t("form.file_upload.label")}
        id={oid}
        onChange={uploadFile}
        disabled={readOnly || !!studyFile || isUploading}
      />
      {error && (
        <ValidationError id={errorDescribedBy(oid)} message={error?.message} />
      )}
      <FilePreview pending={isUploading || !fileInfo}>
        {isUploading ? (
          <FileUploadProgress percent={progress} onCancel={cancelFileUpload} />
        ) : fileInfo ? (
          <>
            <FileDetails
              fileName={fileInfo.fileName}
              fileSize={fileInfo.fileSize}
              displayThumbnail={layout.displayThumbnail}
              isProcessing={isDownloading}
              onClick={downloadFile}
              thumbnail={fileInfo.thumbnail}
              defaultThumbnail={
                <Thumbnail
                  className="file-details__thumbnail"
                  fileName={fileInfo.fileName}
                  onClick={downloadFile}
                />
              }
            />
            {!readOnly && (
              <Button
                className="file-upload__button--trash"
                size="large"
                color="light-gray"
                type="circular"
                label={t("form.file_upload.remove")}
                icon={<TrashIcon />}
                onClick={removeFile}
              />
            )}
          </>
        ) : (
          t("form.file_upload.placeholder")
        )}
      </FilePreview>
    </div>
  );
}

const preSubmitValue = PropTypes.shape({
  studyFileId: PropTypes.number,
  studyId: PropTypes.number,
  studySiteId: PropTypes.number,
  fileGuid: PropTypes.string,
});

const postSubmitValue = PropTypes.shape({
  FileGuid: PropTypes.string,
  FileName: PropTypes.string,
  FileSize: PropTypes.number,
  Thumbnail: PropTypes.string,
});

FileUpload.propTypes = {
  ariaDescribedBy: PropTypes.string,
  layout: PropTypes.shape({
    displayThumbnail: PropTypes.oneOf(Object.values(DisplayThumbnail)),
  }),
  oid: PropTypes.string,
  onBusy: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([preSubmitValue, postSubmitValue]),
};
