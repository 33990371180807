import React, { useEffect, useContext, useRef } from "react";
import userManager, { signinRedirectCallback } from "utils/oidcClientExtension";
import { appInsightsTelemetry } from "ApplicationInsightsService";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { i18n } from "localization/i18n";
import { setCookie, getCookie } from "helpers/cookies";
import { onPostChangePinMessage } from "utils/broadcastChannelUtils";
import { useAsyncError } from "hooks/useAsyncError";

function SigninOidc() {
  const navigate = useNavigate();
  const throwError = useAsyncError();
  const redirectCallbackPromiseRef = useRef(null);
  const { setAuthenticated, setUserMustChangePinCode } =
    useContext(UserContext);

  /*
    Prevent multiple calls to signinRedirectCallback.
    This happens when running the app in development mode (with strict mode).
    Strict mode runs effects twice in development.
  */
  const redirectCallback = async () => {
    if (!redirectCallbackPromiseRef.current) {
      redirectCallbackPromiseRef.current = signinRedirectCallback();
    }

    return redirectCallbackPromiseRef.current;
  }

  useEffect(() => {
    async function signinAsync() {
      let user = null;

      try {
        user = await redirectCallback();
      } catch (e) {
        appInsightsTelemetry.trackException(e);

        // Clear local storage of entries that are not completed and older than 300s (configured in oidc-client)
        // This is to make sure that if the user has to re-login, there is no stale state that might cause issues.
        await userManager.clearStaleState();
      }

      if (user) {
        try {
          i18n.changeLanguage(user.profile.lang);
          setAuthenticated(true);
  
          setCookie(
            process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME,
            user.profile.must_change_pin
          );
          setUserMustChangePinCode(user.profile.must_change_pin);
  
          if (getCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME)) {
            onPostChangePinMessage();
          }
        } catch (e) {
          throwError(e);
        }
      }

      navigate('/')
    }
  

    signinAsync();
  }, [navigate, setAuthenticated, setUserMustChangePinCode, throwError]);

  return <></>;
}

export default SigninOidc;
