import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { accountService } from "services/account";
import { authenticationService } from "services/authentication";
import { onChangePinMessage, onUserLogoutMessage } from 'utils/broadcastChannelUtils';

export function RequireAuth() {
  const {
    authenticated,
    setAuthenticated,
    userMustChangePinCode,
    setUserMustChangePinCode,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [ searchParams ] = useSearchParams();

  const isAuthenticated = authenticationService.isLoggedIn();
  const doesUserMustChangePinCode = accountService.userMustChangePinCode();
  const shouldRedirectToChangePin = doesUserMustChangePinCode && "/change-pin" !== pathname;

  // trigger on component mount, ensure correct authenticated state
  useEffect(() => {
    if (authenticated && !isAuthenticated) {
      setAuthenticated(false);
    }
    if (userMustChangePinCode && !doesUserMustChangePinCode) {
      setUserMustChangePinCode(false);
    }
  });

  useEffect(() => {
    onChangePinMessage(() => navigate("/change-pin"));
    onUserLogoutMessage(() => navigate("/login"));
  }, [navigate]);

  const loginHint = searchParams.get("login_hint");

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ loginHint }} replace />;
  }

  if (shouldRedirectToChangePin) {
    return <Navigate to="/change-pin" replace />;
  }

  return <Outlet />;
}

RequireAuth.propTypes = {
  children: PropTypes.any,
};
