import React, { useEffect } from "react";
import {
  signoutRedirectCallback,
  signinRedirect,
} from "utils/oidcClientExtension";
import { onPostUserLogoutMessage } from "utils/broadcastChannelUtils";
import { useAsyncError } from "hooks/useAsyncError";

function SignoutOidc() {
  const throwError = useAsyncError();

  useEffect(() => {
    async function signoutAsync() {
      try {
        debugger;
        await signoutRedirectCallback();
        onPostUserLogoutMessage();
        await signinRedirect("", localStorage.getItem("user.setting"));
      } catch (e) {
        throwError(e);
      }
    }

    signoutAsync();
  }, [throwError]);

  return <></>;
}

export default SignoutOidc;
