import { authenticationService } from 'services/authentication';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAsyncError } from 'hooks/useAsyncError';

export function Login() {
  const location = useLocation();
  const throwError = useAsyncError();

  useEffect(() => {
    async function loginRedirect() {
      try {
        const fullURL = location.pathname + location.search;
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('authm')) {
          const acr = "authm=" + queryParams.get('authm')
            + " locale=" + queryParams.get('locale');
          await authenticationService.onLoginRedirect(location.state && location.state.loginHint, acr);
        } else {
          await authenticationService.onLoginRedirect(location.state && location.state.loginHint);
        }
      } catch (e) {
        throwError(e);
      }
    }

    loginRedirect();
  }, [location.state, throwError]);

  return (
    <></>
  );
}