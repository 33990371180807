import PropTypes from "prop-types";
import classNames from "classnames";

import "./ToggleButton.css";

export function ToggleButton({
  ariaDescribedBy,
  ariaInvalid,
  disabled = false,
  isSelected = false,
  label = "",
  onClick,
  onFocus,
  role = "radio",
  tabIndex = 0,
  value = "",
}) {
  return (
    <button
      className={classNames({
        "toggle-button": true,
        "toggle-button--selected": isSelected,
        "toggle-button--disabled": disabled,
      })}
      aria-checked={isSelected}
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
      aria-label={label}
      onClick={onClick}
      onFocus={() => onFocus(value)}
      role={role}
      tabIndex={tabIndex}
      type="button"
    >
      <span className="button__label">{label}</span>
    </button>
  );
}

ToggleButton.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  role: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
