import PropTypes from "prop-types";
import classNames from "classnames";
import "./Button.css";

export const ButtonTypes = {
  DEFAULT: "default",
  CIRCULAR: "circular",
  RECTANGLE: "rectangle",
};

export function Button({
  buttonRef,
  className = "",
  color,
  disabled,
  icon,
  id,
  label,
  onClick,
  size = "small",
  type = ButtonTypes.DEFAULT,
}) {
  return (
    <button
      id={id}
      ref={buttonRef}
      className={classNames({
        [className]: className !== "",
        button: true,
        [`button--${size}`]: true,
        [`button--${color}`]: !!color,
        [`button--${type}`]: type !== ButtonTypes.DEFAULT,
        [`button--with-icon`]: !!icon,
      })}
      aria-label={label}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <div className="button__icon">{icon}</div>}
      {type === ButtonTypes.DEFAULT && (
        <span className="button__label">{label}</span>
      )}
    </button>
  );
}

Button.propTypes = {
  buttonRef: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "black",
    "blue",
    "gray",
    "green",
    "light-gray",
    "orange",
    "transparent",
    "purple-dark",
  ]),
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "large"]),
  type: PropTypes.oneOf(Object.values(ButtonTypes)),
};
