import "./FileDetails.css";
import PropTypes from "prop-types";
import classNames from "classnames";

export const DisplayThumbnail = {
  LEFT: "left",
  NONE: "none",
  BELOW: "below",
};

export function FileDetails({
  defaultThumbnail,
  displayThumbnail = DisplayThumbnail.LEFT,
  fileName,
  fileSize,
  isProcessing,
  onClick,
  thumbnail,
}) {
  const formatSize = (bytes) => {
    const sizeSymbols = ["kB", "MB", "GB", "TB", "PB", "EB"];
    if (bytes === 0) {
      return bytes + " " + sizeSymbols[0];
    }
    var i = -1;
    do {
      bytes = bytes / 1024;
      i++;
    } while (bytes >= 1024);
    return Math.max(bytes, 0.1).toFixed(i > 0 ? 1 : 0) + " " + sizeSymbols[i];
  };

  return (
    <section
      className={classNames({
        "file-details": true,
        "file-details--link": !!onClick,
        "file-details--processing": isProcessing,
      })}
    >
      {displayThumbnail !== DisplayThumbnail.NONE &&
        (thumbnail ? (
          <img
            className="file-details__thumbnail"
            src={"data:image/png;base64," + thumbnail}
            alt={fileName}
            onClick={onClick}
          />
        ) : (
          defaultThumbnail
        ))}
      <dl className="file-details__meta">
        <dd>{formatSize(fileSize)}</dd>
        <dt onClick={onClick}>{fileName}</dt>
      </dl>
    </section>
  );
}

FileDetails.propTypes = {
  defaultThumbnail: PropTypes.object,
  displayThumbnail: PropTypes.oneOf(Object.values(DisplayThumbnail)),
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  isProcessing: PropTypes.bool,
  onClick: PropTypes.func,
  thumbnail: PropTypes.string,
};
