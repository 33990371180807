import classNames from "classnames";
import PropTypes from "prop-types";

import "./NumericScale.css";

export function NumericScale({ vertical = false, rtl = false }) {
  return (
    <div
      className={classNames({
        "numeric-scale": true,
        "numeric-scale--vertical": vertical,
      })}
    >
      {Array.from(Array(101), (x, i) => {
        const style = vertical
          ? { bottom: `${i}%`, transform: `translateY(${i}%)` }
          : rtl
          ? { right: `${i}%`, transform: `translateX(${i}%)` }
          : { left: `${i}%`, transform: `translateX(-${i}%)` };

        return (
          <div
            key={i}
            className={classNames({
              "numeric-scale__tick": true,
              "numeric-scale__tick--long": i % 10 === 0,
            })}
            style={style}
          ></div>
        );
      })}
    </div>
  );
}

NumericScale.propTypes = {
  vertical: PropTypes.bool,
  rtl: PropTypes.bool,
};
