import PropTypes from "prop-types";
import classNames from "classnames";
import { useContext, useEffect } from "react";

import { useFetchIsRoomActive } from "hooks/api/useFetchIsRoomActive";
import { UserContext } from "context/UserContext";

import "./VideoCall.css";

const VIDEO_CALL_ID = "whereby-video-call";
const IS_ACTIVE_POLLING = 60000; // Polling interval in milliseconds (1 min)

export function VideoCall({ allow = "camera; microphone; fullscreen; speaker; display-capture", hidden, room, title }) {
  // Context to handle active call and idle timer
  const { addBusyFormItem, removeBusyFormItem } = useContext(UserContext);

  useEffect(() => {
    // Ensure to resume idle timer when call has ended
    return function cleanup() {
      removeBusyFormItem(VIDEO_CALL_ID);
    };
  }, [removeBusyFormItem]);

  useFetchIsRoomActive(room.meetingId, IS_ACTIVE_POLLING, (data) => {
    // Prevent being logged out during an active call
    if (data) {
      addBusyFormItem(VIDEO_CALL_ID);
    } else {
      removeBusyFormItem(VIDEO_CALL_ID);
    }
  }); // Polling interval in milliseconds

  return (
    <iframe
      className={classNames({
        "video-call--hidden": hidden,
      })}
      title={title}
      src={room.roomUrl}
      allow={allow}
    ></iframe>
  );
}

VideoCall.propTypes = {
  allow: PropTypes.string,
  hidden: PropTypes.bool,
  room: PropTypes.shape({
    meetingId: PropTypes.string.isRequired,
    roomUrl: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
