import "./ChangePinForm.css";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { accountService } from "services/account";

import { Button } from "components/Base/Button/Button";
import { LabelInput } from "components/Base/LabelInput/LabelInput";
import { pinCodeFormat } from "helpers/format-helpers";
import { StatusMessage } from "components/Base/StatusMessage/StatusMessage";

export function ChangePinForm({ className }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [newPinCode, setNewPinCode] = useState("");
  const [hasJustInitiated, setHasJustInitiated] = useState(false);
  const [newPinCodeRepeat, setNewPinCodeRepeat] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [disableChange, setDisableChange] = useState(false);

  useEffect(() => {
    setError("");
    setSuccess("");
  }, [newPinCode, newPinCodeRepeat]);

  const changePin = async (e) => {
    e.preventDefault();

    if (disableChange || !validatePinCodes()) {
      return false;
    }
    try {
      setDisableChange(true);
      const result = await accountService.changePin(
        newPinCode,
        newPinCodeRepeat
      );
      if (result && result.success) {
        setHasJustInitiated(result && result.sentReference);
        setSuccess(t("change_pin.successful_change"));
      } else{
        setError(t("change_pin.change_failed")); // Default to most likely error is message missing
        setDisableChange(false);
      }
    } catch (e) {
      setError(e.message ?? t("change_pin.change_failed")); // Default to most likely error is message missing
      setDisableChange(false);
    }
  };

  const redirectToStart = (e) => {
    e.preventDefault();
    if (hasJustInitiated){
      navigate('/welcome');
    } else{
      navigate("/");
    }
  };

  const validatePinCodes = () => {
    if (newPinCode !== newPinCodeRepeat) {
      setError(t("change_pin.no_match"));
      return false;
    }

    return true;
  };

  return (
    <form
      className={classNames({
        "change-pin-form": true,
        [className]: !!className,
      })}
      onSubmit={changePin}
      aria-label={t("change_pin.new_pin")}
    >
      <LabelInput
        id="new-pincode"
        type="password"
        pattern={pinCodeFormat}
        required={true}
        ariaDescribedBy="change-pin-details"
        value={newPinCode}
        onChange={(e) => setNewPinCode(e.target.value)}
        label={t("change_pin.new_pin")}
        className="change-pin-form__row change-pin-form__input"
        disabled={disableChange}
      />

      <LabelInput
        id="new-pincode-repeat"
        type="password"
        pattern={pinCodeFormat}
        required={true}
        ariaDescribedBy="change-pin-details"
        value={newPinCodeRepeat}
        onChange={(e) => setNewPinCodeRepeat(e.target.value)}
        label={t("change_pin.repeat_new_pin")}
        className="change-pin-form__row change-pin-form__input"
        disabled={disableChange}
      />

      <StatusMessage
        id="change-pin-details"
        className="change-pin-form__details"
        isError={!!error}
        isSuccess={!!success}
        message={
          !!error
            ? error
            : !!success
            ? success
            : t("change_pin.valid_format", { pinCodeDigits: 4 })
        }
      />

      <div className="change-pin-form__row change-pin-form__buttons">
        <Button
          size="large"
          color="orange"
          label={t("change_pin.submit")}
          disabled={disableChange}
        />
        {success.length > 0 && (
          <Button
            size="large"
            color="green"
            label={t("change_pin.continue")}
            onClick={redirectToStart}
          />
        )}
      </div>
    </form>
  );
}

ChangePinForm.propTypes = {
  className: PropTypes.string,
};
