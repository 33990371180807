import PropTypes from "prop-types";
import classNames from "classnames";
import "./CheckBox.css";

export function CheckBox({
  id,
  label,
  value = "",
  checked = false,
  disabled = false,
  onChange,
  ariaDescribedBy
}) {
  return (<label htmlFor={id} className="checkbox__wrapper">
    <input
      id={id}
      type="checkbox"
      className={classNames({
        "checkbox": true,
        "checkbox--checked": checked,
        "checkbox--disabled": disabled,
      })}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      aria-describedby={ariaDescribedBy}
    />
    <svg width="32" height="32" viewBox="0 0 30 30" aria-hidden="true" focusable="false">
      <rect className="checkbox__bg" x="1" y="1" width="28" height="28" rx="14" fill="none" stroke="currentColor"/>
      <path className="checkbox__checkmark" d="M12.7727 18.2015L9.19318 14.7537L8 15.903L12.7727 20.5L23 10.6493L21.8068 9.5L12.7727 18.2015Z" stroke="transparent" fill="none"/>
    </svg>
    {label}
  </label>)
}

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  ariaDescribedBy: PropTypes.string,
};
