import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "content/icons/arrow.svg";

import "./Select.css";

export function Select({
  ariaDescribedBy,
  disabled,
  id,
  onChange,
  options = [],
  valid = true,
  value = "",
}) {
  // value can be either string or number
  // eslint-disable-next-line eqeqeq
  const selectedOption = options.find((option) => option.value == value);
  const selectedLabel =
    selectedOption && value !== "" ? selectedOption.label : "";

  return (
    <div
      data-testid="select"
      className={classNames({
        select: true,
        "select--selected": value ?? "",
        "select--invalid": !valid,
        "select--disabled": disabled,
      })}
    >
      <select
        id={id}
        className="select__native-select"
        disabled={disabled}
        value={value ?? ""}
        onChange={(evt) => onChange(evt.target.value)}
        aria-invalid={!valid}
        aria-describedby={ariaDescribedBy}
        title={selectedLabel}
      >
        {options.map(({ value, label, disabled }) =>
          disabled ? null : (
            <option className="select__option" key={value} value={value}>
              {label}
            </option>
          )
        )}
      </select>
      <ArrowIcon className="select__icon" />
    </div>
  );
}

Select.propTypes = {
  ariaDescribedBy: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
