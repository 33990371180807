import { WebStorageStateStore } from 'oidc-client';

export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_ME_IDP_URL,
  client_id: "new-viedocme-oidc",
  redirect_uri: window.location.origin + "/signin-oidc",
  silent_redirect_uri: window.location.origin + "/signin-oidc",
  post_logout_redirect_uri: window.location.origin + "/signout-oidc",
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 300, //5 mins
  response_type: "code",
  loadUserInfo: true,
  scope: "openid profile offline_access viedoc.me.api viedoc.me.pdf settings monitor IdentityServerApi",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: false
};